import { useState } from 'react';


import SiteNavbar from './components/SiteNavbar';
import SiteFooter from './components/SiteFooter';
import AboutMe from './components/AboutMe';
import Projects from './components/Projects'





const MainPage = () => {
  // State to toggle dark mode
  const [darkMode, setDarkMode] = useState(true);


  // Function to toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };




  return (
    <div className={`${darkMode ? 'dark bg-slate-950' : 'bg-white'}`}>
      <SiteNavbar isDarkMode={darkMode} toggleDarkMode={toggleDarkMode} />

      <div className="min-h-screen flex flex-col">



        {/* Main Content - Flex-grow makes sure this section takes up the remaining space */}
        <main className="flex-grow">
          <AboutMe isDarkMode={darkMode} />
          <Projects isDarkMode={darkMode} />
        </main>
        <SiteFooter isDarkMode={darkMode} />

      </div>
    </div>
  );
};

export default MainPage;
