import { Footer } from 'flowbite-react'

const SiteFooter = ({ isDarkMode }: { isDarkMode: boolean }) => {
  return (
    <Footer className={`${isDarkMode ? 'bg-slate-950 text-white' : 'text-gray-900'} w-full text-center py-4`} container={true}>
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 Atte Räisänen. All Rights Reserved</span>
    </Footer>
  )
}

export default SiteFooter;