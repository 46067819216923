import { Navbar } from 'flowbite-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

const SiteNavbar = ({ isDarkMode, toggleDarkMode }: { isDarkMode: boolean, toggleDarkMode: any }) => {
  return (
    <div>
      <Navbar fluid={true} className={`${isDarkMode ? 'bg-slate-950 text-white' : 'bg-white text-gray-900'}`}>
        <div className="flex space-x-4 mt-2">
          <Navbar.Brand href="#">
            <span className={` text-xl ml-4 font-semibold whitespace-nowrap ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
              atteraisanen.dev
            </span>
          </Navbar.Brand>
          {/* LinkedIn and GitHub Icons */}
          <a href="https://www.linkedin.com/in/atte-r%C3%A4is%C3%A4nen-2b3195205/" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
          <a href="https://github.com/atteraisanen" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
            <FontAwesomeIcon icon={faGithub} size="2x" />
          </a>
        </div>

        <div className="ml-auto flex mt-2">
          {/* Dark Mode Toggle */}
          <button
            onClick={toggleDarkMode}
            className={`${isDarkMode ? 'bg-slate-950' : 'bg-white'})text-white rounded-full p-2 hover:bg-slate-900 ml-auto mr-4`}
          >
            {isDarkMode ? '🌙' : '☀️'}
          </button>
        </div>
      </Navbar>
    </div>
  )
};

export default SiteNavbar;