import { getFirestore, collection, getDocs } from "firebase/firestore";
import { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { DocumentData } from 'firebase/firestore';
import ProjectCard from './ProjectCard';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const Projects = ({ isDarkMode }: { isDarkMode: boolean }) => {
  const [projects, setProjects] = useState(Array<DocumentData>);
  
  const getProjects = async () => {
    const projectCollection = collection(db, "projects");
    const projectSnapshot = await getDocs(projectCollection);
    const projectList = projectSnapshot.docs.map(doc => doc.data());
    setProjects(projectList);
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="mt-8 max-w-3xl mx-auto px-4 sm:px-0">
      <h2 className={`text-xl sm:text-2xl font-bold mb-6 sm:mb-8 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>PROJECTS</h2>
      <div className="flex flex-col gap-6 sm:gap-8">
        {projects.map(project => (
          <div key={project.id}>
            <ProjectCard darkMode={isDarkMode} imgUrl={project.imageUrl} projectTitle={project.title} projectDescription={project.description} projectDate={project.completionDate} url={project.url} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
