const AboutMe = ({ isDarkMode }: { isDarkMode: boolean }) => {
  return (
    <div className="py-8 sm:py-16 px-4 sm:px-0 max-w-3xl mx-auto">
      <h2 className={`text-xl sm:text-2xl font-bold mb-4 sm:mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>ABOUT ME</h2>
      <p className={`text-base sm:text-lg ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`}>
        Hey, I'm Atte. I enjoy dumb stuff like full stack development and
        coming up with intricate solutions to simple problems. I'm currently on my third year of
        completing a Bachelor's degree in Information and Communication
        Technology at Metropolia UAS.
      </p>
      <p className={`text-base sm:text-lg mt-3 sm:mt-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-800'}`}>
        Here on this website you'll find a list of some of the projects I've spent way too many
        hours on.
      </p>
    </div>
  );
};

export default AboutMe;
