import { Card } from 'flowbite-react'
import { Link } from 'react-router-dom'
const ProjectCard = ({ darkMode, imgUrl, projectTitle, projectDescription, projectDate, url }: { darkMode: boolean, imgUrl: string, projectTitle: string, projectDescription: string, projectDate: string, url: string }) => {
  return (
    <Link to={url} >
      <Card className={`${darkMode ? 'bg-slate-950 text-white' : 'text-gray-900'} w-full max-w-3xl mx-auto hover:scale-105 hover:cursor-pointer`}>
        <div className="flex">
          <img
            src={`${imgUrl}`}
            alt="Project"
            className="w-20 h-20 object-cover rounded-lg mr-4"
          />
          <div>
            <h3 className="text-xl font-semibold">{projectTitle}</h3>
            <p className={`${darkMode ? 'text-gray-300' : 'text-gray-700'} break-words`}>
              {projectDescription}
            </p>
            <p className={`${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>{projectDate}</p>
          </div>
        </div>
      </Card>
    </Link>
  );

}

export default ProjectCard;